.f1 {
  flex: 1;
}

.f2 {
  flex: 2;
}

.f3 {
  flex: 3;
}

.f4 {
  flex: 4;
}

.f5 {
  flex: 5;
}

.f6 {
  flex: 6;
}

.f7 {
  flex: 7;
}

.f8 {
  flex: 8;
}

.f9 {
  flex: 9;
}

.f10 {
  flex: 10;
}

.fg1 {
  flex-grow: 1;
}