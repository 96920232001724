.recharts-wrapper {
  background-color: var(--c255);
}

.recharts-line-dots circle {
  stroke: var(--c160);
  fill: var(--c50);
}

.recharts-line-dots circle {
  r: 2;
}

.recharts-cartesian-axis-tick-value {
  fill: var(--c50);
}

.recharts-cartesian-grid line {
  stroke: var(--c180);
}

.custom-tick {
  width: 20px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}