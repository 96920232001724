.right0 {
  right: 0px;
}

.right10 {
  right: 10px;
}

.right20 {
  right: 20px;
}

.right30 {
  right: 30px;
}

.right40 {
  right: 40px;
}

.right50 {
  right: 50px;
}

.right60 {
  right: 60px;
}

.right70 {
  right: 70px;
}

.right80 {
  right: 80px;
}

.right90 {
  right: 80px;
}

.right100 {
  right: 100px;
}