.bbs-row {
  /* border-radius: 5px;
  background-color: rgb(243, 248, 253); */
  /* padding-left: 5px;
  padding-right: 5px; */
}

.bbs-title-row {
  background-color: rgb(132, 128, 93);
}

.bbs-col {
  color: var(--c50);
}

.bbs-avatar-box {}

.bbs-user-icon {
  width: 25px;
  border-radius: 80px;
}

.bbs-search-input {
  float: left;
  font-size: 14px;
  padding: 2px 10px;
  width: 65%;
  border-radius: 60px 0px 0px 60px;
  border: 1px solid #dddfe2;
  border-width: 1px 1px 1px 1px;
  color: rgb(83, 83, 83);
}

.bbs-search-button {
  float: left;
  width: 30px;
  height: 22px;
  background-color: rgb(180, 180, 180);
  border-radius: 0px 60px 60px 0px;
  font-size: 16px;
  padding-top: 1px;
  padding-right: 3px;
  box-sizing: border-box;
}

.bbs-top-box {
  margin: 10px 0px;
}

.bbs-page-number {
  min-width: 18px;
  height: 17px;
  padding: 0px 5px 1px 5px;
  background-color: rgb(27, 60, 116);
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 13.5px;
  color: #dddfe2;
}

.bbs-page-number-selected {
  background-color: #E60000;
}