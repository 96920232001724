.br1 {
  border-radius: 1px;
}

.br2 {
  border-radius: 2px;
}

.br3 {
  border-radius: 3px;
}

.br4 {
  border-radius: 4px;
}

.br5 {
  border-radius: 5px;
}

.br6 {
  border-radius: 6px;
}

.br7 {
  border-radius: 7px;
}

.br8 {
  border-radius: 8px;
}

.br9 {
  border-radius: 9px;
}

.br10 {
  border-radius: 10px;
}

.br11 {
  border-radius: 11px;
}

.br12 {
  border-radius: 12px;
}

.br13 {
  border-radius: 13px;
}

.br14 {
  border-radius: 14px;
}

.br15 {
  border-radius: 15px;
}

.br20 {
  border-radius: 20px;
}

.br30 {
  border-radius: 30px;
}

.br100 {
  border-radius: 100px;
}

.br100p {
  border-radius: 100%;
}

.brtl8 {
  border-top-left-radius: 8px;
}

.brbl8 {
  border-bottom-left-radius: 8px;
}

.brbr8 {
  border-bottom-right-radius: 8px;
}


.brtr8 {
  border-top-right-radius: 8px;
}

.brtr10 {
  border-top-right-radius: 10px;
}

.brtr20 {
  border-top-right-radius: 20px;
}

.brtl10 {
  border-top-left-radius: 10px;
}

.brbl10 {
  border-bottom-left-radius: 10px;
}

.brbr10 {
  border-bottom-right-radius: 10px;
}

.brcd8 {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.brr10 {
  border-radius: 0px 10px 10px 0px;
}

.brr15 {
  border-radius: 0px 15px 15px 0px;
}

.brr20 {
  border-radius: 0px 20px 20px 0px;
}

.brb10 {
  border-radius: 0px 0px 10px 10px;
}

.brb15 {
  border-radius: 0px 0px 15px 15px;
}

.brb20 {
  border-radius: 0px 0px 20px 20px;
}