.channel-header-bar {
  width: 100%;
  color: rgb(100, 100, 100);
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
}

.avtive-indicator {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  margin-bottom: 3px;
  background-color: rgb(97, 175, 244);
}

.contents-header {
  display: flex;
  flex-direction: row;
  float: left;
  font-size: 13.5px;
  height: 17px;
  margin-left: 8px;
}

.contents-type {
  margin-left: 10px;
  border-radius: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 0px;
}

.contents-round-box {
  /* border-radius: 10px 10px 0px 0px; */
  background-color: var(---c240);
  box-sizing: border-box;
}