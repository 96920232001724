.people-layout-box {
  height: calc(100vh - var(--header-height));
  overflow: hidden;
}

.people-menu {
  box-sizing: border-box;
  height: 35px;
  width: 100%;
  margin: 5px;
  padding: 3px;
  font-size: 17px;
}

.people-menu-on {
  background-color: rgb(117, 198, 255);
  background-color: white;
  color: rgb(61, 61, 61);
}

.people-menu-divider {
  height: 1px;
  background-color: rgb(215, 215, 215);
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.channel-user-led {
  position: absolute;
  right: -2px;
  bottom: 1px;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  border: 1px solid rgb(180, 180, 180);
  z-index: 2;
}

.people-menu-icon {
  font-size: 14px;
  padding-left: 5px;
}

.people-user-icon {
  height: 28px;
  border-radius: 100%;
}

.header-channel-icon {
  height: 28px;
  border-radius: 100%;
}

.people-button {
  height: 18px;
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  /* padding: 8px; */
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  background-color: #ECF3FF;
}

.list-menu-box {
  position: absolute;
  border-radius: 0px 10px 0px 0px;
  top: 17px;
  bottom: 0px;
  /* left: -200px; */
  width: 350px;
  /* background-color: rgb(200, 220, 220); */
  color: #fff;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* transition: right 0.5s ease; */
  z-index: 10;
}

.list-menu-visible {
  left: var(--layout-left-menu-width);
  transition: left 0.3s ease;
}

.list-menu-hidden {
  left: -400px;
  transition: left 0.3s ease;
}