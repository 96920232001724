.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.z5 {
  z-index: 5;
}

.z6 {
  z-index: 6;
}

.z7 {
  z-index: 7;
}

.z8 {
  z-index: 8;
}

.z9 {
  z-index: 9;
}

.z10 {
  z-index: 10;
}

.z20 {
  z-index: 20;
}

.z30 {
  z-index: 30;
}

.z99 {
  z-index: 99;
}

.z9999 {
  z-index: 9999;
}