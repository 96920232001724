/*
auto: 브라우저가 요소에 기본 커서를 설정합니다.
default: 기본 커서(일반적으로 화살표)를 설정합니다.
none: 커서를 숨깁니다.
context-menu: 컨텍스트 메뉴를 표시하는 기본 커서를 설정합니다.
help: 도움말 커서를 설정합니다.
pointer: 링크를 가리키는 손가락 모양의 커서를 설정합니다.
progress: 진행 중을 나타내는 커서를 설정합니다.
wait: 사용자의 작업을 기다리는 커서를 설정합니다.
cell: 텍스트를 편집 가능한 셀로 가리키는 커서를 설정합니다.
crosshair: 십자선으로 이루어진 커서를 설정합니다.
text: 텍스트 선택을 나타내는 커서를 설정합니다.
vertical-text: 수직 텍스트 선택을 나타내는 커서를 설정합니다.
alias: 링크 목록을 나타내는 커서를 설정합니다.
copy: 객체 복사를 나타내는 커서를 설정합니다.
move: 객체 이동을 나타내는 커서를 설정합니다.
no-drop: 요소를 드롭할 수 없음을 나타내는 커서를 설정합니다.
not-allowed: 사용할 수 없는 상태를 나타내는 커서를 설정합니다.
*/

.cursor-default {
  cursor: default;
}

.cursor-click {
  cursor: pointer;
}