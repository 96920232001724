.header-parent {
  padding-bottom: var(--header-body-bottom);
  min-width: 800px;
}

.header-body {
  height: var(--header-body-height);
  -webkit-box-shadow: 0 3px 4px -4px var(--c120);
  -moz-box-shadow: 0 3px 4px -4px var(--c120);
  box-shadow: 0 3px 4px -4px var(--c120);
  z-index: 20;
}

.icon-number {
  position: absolute;
  right: -10px;
  bottom: -2px;
  background-color: rgb(230, 0, 0);
  border-radius: 20px;
  padding: 3px 5px;
  min-width: 10px;

  font-size: 11px;
  color: rgb(230, 230, 230);
}

.top-logo {
  float: left;
  height: 42px;
  pointer-events: none;
  margin: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.top-banner {
  float: left;
  height: 35px;
  width: 120px;
  margin: 10px 0px 4px 0px;
  border-radius: 8px;
}

.top-member {
  border-radius: 100px;
  height: 42px;
  pointer-events: none;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  /* opacity: 0.5; */
}

.ver-label {
  float: right;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 16px;
  color: #aaaaaa;
  margin-left: 15px;
  font-weight: 500;
}

.search-type {
  float: left;
  background-color: var(--c210);
  width: 100px;
  height: 40px;
  margin-top: 7px;
  border-radius: 60px 0px 0px 60px;
  font-size: 18px;
}

.search-input {
  float: left;
  font-size: 16px;
  padding: 10px 70px 10px 20px;
  width: 350px;
  border-radius: 60px;
  background-color: var(--c245);
  border: 1px solid var(--c210);
  border-width: 1px 1px 1px 1px;
  margin-top: 7px;
  margin-bottom: 8PX;
  color: var(--c80);
}

.search-button {
  position: absolute;
  top: 2px;
  right: 2px;
  float: left;
  width: 50px;
  height: 36px;
  background-color: var(--c220);
  border-radius: 0px 60px 60px 0px;
  margin-top: 7px;
}

.search-button-icon {
  font-size: 28px;
  color: var(--c255);
  left: 10px;
  margin-top: 5px;
  margin-left: 12px;
}

/* Notification */

.notification-container {
  overflow: hidden;
  transition: right 0.3s ease;
}

.notification-content {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: -300%;
  width: 100%;
  transition: right 0.3s ease;
  border-radius: 100px 0px 0px 100px;
}

.notification-container.show .notification-content {
  right: 0;
}