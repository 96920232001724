.p1 {
  padding: 1px;
}

.p2 {
  padding: 2px;
}

.p3 {
  padding: 3px;
}

.p4 {
  padding: 4px;
}

.p5 {
  padding: 5px;
}

.p6 {
  padding: 6px;
}

.p7 {
  padding: 7px;
}

.p8 {
  padding: 8px;
}

.p9 {
  padding: 9px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p30 {
  padding: 30px;
}

.pt1 {
  padding-top: 1px;
}

.pt2 {
  padding-top: 2px;
}

.pt3 {
  padding-top: 3px;
}

.pt4 {
  padding-top: 4px;
}

.pt5 {
  padding-top: 5px;
}

.pt6 {
  padding-top: 6px;
}

.pt7 {
  padding-top: 7px;
}

.pt8 {
  padding-top: 8px;
}

.pt9 {
  padding-top: 9px;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pb1 {
  padding-bottom: 1px;
}

.pb2 {
  padding-bottom: 2px;
}

.pb3 {
  padding-bottom: 3px;
}

.pb4 {
  padding-bottom: 4px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb6 {
  padding-bottom: 6px;
}

.pb7 {
  padding-bottom: 7px;
}

.pb8 {
  padding-bottom: 8px;
}

.pb9 {
  padding-bottom: 9px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pl3 {
  padding-left: 3px;
}
.pl4 {
  padding-left: 4px;
}

.pl5 {
  padding-left: 5px;
}

.pl8 {
  padding-left: 8px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.pl30 {
  padding-left: 30px;
}

.pr1 {
  padding-right: 1px;
}

.pr2 {
  padding-right: 2px;
}

.pr3 {
  padding-right: 3px;
}

.pr4 {
  padding-right: 4px;
}

.pr5 {
  padding-right: 5px;
}

.pr8 {
  padding-right: 8px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

.pr30 {
  padding-right: 30px;
}

.plr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.plr8 {
  padding-left: 8px;
  padding-right: 8px;
}

.plr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ptb1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.ptb2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ptb3 {
  padding-top: 3px;
  padding-bottom: 3px;
}