.left-1 {
  left: -1px;
}

.left-100 {
  left: -20px;
}

.left0 {
  left: 0px;
}

.left10 {
  left: 10px;
}

.left15 {
  left: 15px;
}

.left20 {
  left: 20px;
}
.left30 {
  left: 30px;
}
.left40 {
  left: 40px;
}
.left50 {
  left: 50px;
}

.left60 {
  left: 60px;
}
.left70 {
  left: 70px;
}
.left80 {
  left: 80px;
}
.left90 {
  left: 90px;
}
.left100 {
  left: 100px;
}