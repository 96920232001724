.custom-video-player {
  position: relative;
  width: 100%;
  margin: auto;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #505050;
  color: white;
  margin-top: 5px;
  border-radius: 100px;
  padding: 5px 30px 5px 30px;
}
