.message-box {
  /* display: flex;
  flex-direction: row; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  /* background-color: rgb(255, 245, 245); */
  background-color: white;
  border-radius: 13px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-style: solid;
  border-color: #eee5e5;
  border-width: 1px;
}

.message-box-row {
  display: flex;
  flex-direction: row;
  background-color: blueviolet;
  width: 100%;
}

.login-box {
  width: 320px;

  background-color: white;
  color: grey;
  display: grid;

  place-items: center;
  justify-content: center;

  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 10px;
  border-radius: 13px;

  padding-top: 20px;
  padding-bottom: 15px;

  border-style: solid;
  border-color: #eee5e5;
  border-width: 1px;
}

.login-main-btn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 200px;
  font-size: 15px;
  font-weight: bolder;
  cursor: pointer;
}

.login-sub-btn {
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 5px;
  margin-bottom: 5px; */
  width: 150px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}


.login-input {
  font-size: 14px;
  padding: 8px 12px;
  width: 240px;
  background-color: var(--c255);
  border-radius: 6px;
  border: 1px solid var(--c225);
  margin-bottom: 10PX;
  color: var(--c80);
}

.change-password-input {
  font-size: 13px;
  padding: 8px 12px;
  width: 180px;
  background-color: var(--c255);
  border-radius: 6px;
  border: 1px solid var(--c225);
  margin-bottom: 10PX;
  color: var(--c80);
}

.login-btn-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.login-button {
  background-color: #1877f2;
  color: var(--c250);
  padding: 8px;
  border-radius: 0.4em;
  text-align: center;
  user-select: none;
}

.new-account-btn {
  background-color: #42b72a;
  font-size: 15px;
  font-weight: bolder;
  padding: 8px;
  border-radius: 0.4em;
  color: var(--c250);
  text-align: center;
}

.new-account-disabled-btn {
  background-color: #c2e5ba;
  font-size: 15px;
  font-weight: bolder;
  padding: 8px;
  border-radius: 0.4em;
  color: var(--c160);
  text-align: center;
}

.password-reset-button {
  background-color: #f4513c;
  color: var(--c250);
  padding: 8px;
  border-radius: 0.4em;
  text-align: center;
}

.forgot-password {
  padding: 3px;
  color: #1877f2;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.agree-policy {
  margin: 0px auto 8px auto;
  color: #777;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.shadow-box {
  -webkit-box-shadow: 0px 2px 4px -3px #777;
  -moz-box-shadow: 0px 2px 4px -3px #777;
  box-shadow: 0px 2px 4px -3px #777;
}

.shadow-box2 {
  -webkit-box-shadow: 0 4px 8px rgba(200, 200, 200, 0.2), 0 2px 4px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0 4px 8px rgba(200, 200, 200, 0.2), 0 2px 4px rgba(50, 50, 50, 0.4);
  box-shadow: 0 4px 8px rgba(200, 200, 200, 0.2), 0 2px 4px rgba(50, 50, 50, 0.4);
}

.separator {
  /* border: 1px solid #dddddd; */
  /* width: 260px; */
  height: 1px;
  background-color: var(--c220);
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.login-error-message {
  width: 80%;
  text-align: center;
  font-size: 14px;
  background-color: rgb(255, 69, 69);
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
}