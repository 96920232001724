.layout-body {
  flex-grow: 1;
  background-color: var(--c250);
  overflow-y: hidden;
  overflow: hidden;
}

.win-box {
  /* margin: 0px 5px 0px 0px; */
  box-sizing: border-box;
}

.top-modal {
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}