.w0 {
  width: 0px;
}

.w1 {
  width: 1px;
}

.w2 {
  width: 2px;
}

.w3 {
  width: 3px;
}

.w4 {
  width: 4px;
}

.w5 {
  width: 5px;
}

.w6 {
  width: 6px;
}

.w7 {
  width: 7px;
}

.w8 {
  width: 8px;
}

.w9 {
  width: 9px;
}

.w10 {
  width: 10px;
}

.w11 {
  width: 11px;
}

.w12 {
  width: 12px;
}

.w13 {
  width: 13px;
}

.w14 {
  width: 14px;
}

.w15 {
  width: 15px;
}

.w16 {
  width: 16px;
}

.w17 {
  width: 17px;
}

.w18 {
  width: 18px;
}

.w19 {
  width: 19px;
}

.w20 {
  width: 20px;
}

.w21 {
  width: 21px;
}

.w22 {
  width: 22px;
}

.w23 {
  width: 23px;
}

.w24 {
  width: 24px;
}

.w25 {
  width: 25px;
}

.w26 {
  width: 26px;
}

.w27 {
  width: 27px;
}

.w28 {
  width: 28px;
}

.w29 {
  width: 29px;
}

.w30 {
  width: 30px;
}

.w35 {
  width: 35px;
}

.w40 {
  width: 40px;
}

.w41 {
  width: 41px;
}

.w42 {
  width: 42px;
}

.w43 {
  width: 43px;
}

.w44 {
  width: 44px;
}

.w45 {
  width: 45px;
}

.w50 {
  width: 50px;
}

.w55 {
  width: 55px;
}

.w60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.w80 {
  width: 80px;
}

.w90 {
  width: 90px;
}


.w100 {
  width: 100px;
}

.w110 {
  width: 110px;
}

.w120 {
  width: 120px;
}

.w130 {
  width: 130px;
}

.w140 {
  width: 140px;
}

.w150 {
  width: 150px;
}

.w160 {
  width: 160px;
}

.w170 {
  width: 170px;
}

.w180 {
  width: 180px;
}

.w190 {
  width: 190px;
}

.w200 {
  width: 200px;
}

.w210 {
  width: 210px;
}

.w220 {
  width: 220px;
}

.w230 {
  width: 230px;
}

.w240 {
  width: 240px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.w350 {
  width: 350px;
}

.w400 {
  width: 400px;
}

.w500 {
  width: 500px;
}

.w600 {
  width: 600px;
}

.w700 {
  width: 700px;
}

.w750 {
  width: 750px;
}

.w800 {
  width: 800px;
}

.w900 {
  width: 900px;
}

.w1000 {
  width: 1000px;
}

.w1100 {
  width: 1100px;
}

.w1200 {
  width: 1200px;
}

.w1300 {
  width: 1300px;
}

.w1400 {
  width: 1400px;
}

.w1500 {
  width: 1500px;
}

.w50p {
  width: 50%;
}

.min10 {
  min-width: 10px;
}

.min20 {
  min-width: 20px;
}

.min30 {
  min-width: 30px;
}

.min80 {
  min-width: 80px;
}

.min90 {
  min-width: 90px;
}

.min100 {
  min-width: 100px;
}

.min110 {
  min-width: 110px;
}

.min120 {
  min-width: 120px;
}

.min130 {
  min-width: 130px;
}

.min140 {
  min-width: 140px;
}

.min150 {
  min-width: 150px;
}

.min160 {
  min-width: 160px;
}

.min170 {
  min-width: 170px;
}

.min180 {
  min-width: 180px;
}

.min190 {
  min-width: 190px;
}

.min200 {
  min-width: 200px;
}

.min250 {
  min-width: 250px;
}

.min300 {
  min-width: 300px;
}

.min400 {
  min-width: 400px;
}

.min500 {
  min-width: 500px;
}

.min550 {
  min-width: 550px;
}

.min600 {
  min-width: 600px;
}

.min650 {
  min-width: 650px;
}

.min700 {
  min-width: 700px;
}

.min800 {
  min-width: 800px;
}

.max70 {
  max-width: 70px;
}

.max100 {
  max-width: 100px;
}

.max110 {
  max-width: 110px;
}

.max120 {
  max-width: 120px;
}

.max130 {
  max-width: 130px;
}

.max140 {
  max-width: 140px;
}

.max150 {
  max-width: 150px;
}

.max200 {
  max-width: 200px;
}

.max250 {
  max-width: 250px;
}

.max300 {
  max-width: 300px;
}

.max320 {
  max-width: 320px;
}

.max350 {
  max-width: 350px;
}

.max400 {
  max-width: 400px;
}

.max450 {
  max-width: 450px;
}

.max500 {
  max-width: 500px;
}

.max600 {
  max-width: 600px;
}

.max650 {
  max-width: 650px;
}

.max700 {
  max-width: 700px;
}

.max750 {
  max-width: 750px;
}

.max800 {
  max-width: 800px;
}

.max850 {
  max-width: 850px;
}

.max900 {
  max-width: 900px;
}

.max950 {
  max-width: 950px;
}

.max1000 {
  max-width: 1000px;
}

.max1100 {
  max-width: 1100px;
}

.max1200 {
  max-width: 1200px;
}

.max1300 {
  max-width: 1300px;
}

.max1400 {
  max-width: 1400px;
}

.max1500 {
  max-width: 1500px;
}

.w-test {
  width: 800px;
}

.mw100p {
  max-width: 100%;
}

.w80p {
  width: 80%;
}

.w90p {
  width: 90%;
}

.w100p {
  width: 100%;
}