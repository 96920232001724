.verify-email-box {
  color: gray;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.verify-email-content {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.email-image {
  color: #42b72a;
  font-size: 50px;
}

.verify-email-description {
  font-size: 14px;
  text-align: left;
}