.profile-test {
  width: 350px;
  padding: 10px;
  background-color: rgb(186, 186, 186);
}

.profile-box {
  width: 300px;
  padding-bottom: 8px;
  background-color: white;
  border-radius: 10px;
  /* border-radius: 0px 0px 10px 10px; */
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 10px;

  border-style: solid;
  border-color: #eee5e5;
  border-width: 1px;
}

.profile-gap {
  width: 5px;
}

.profile-image {
  border-radius: 100px;
  width: 60px;
  height: 60px;
  margin-bottom: 6px;
}

.profile-update-box {
  background-color: rgb(248, 255, 234);
  height: 250px;
  text-align: center;
  color: gray;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile-item-group {
  /* margin-top: 5px;
  margin-bottom: 5px; */
}

.profile-item {
  /* margin-top: 5px;
  margin-bottom: 5px; */
}

.profile-label {
  background-color: rgb(235, 235, 235);
  font-size: 13.5px;
  font-weight: 500;
  color: rgb(150, 150, 150);
  padding: 3px 5px 3px 5px;
  border-radius: 8px;
  width: 70px;
  margin-right: 4px;
}

.profile-value {
  font-size: 14px;
  font-weight: 500;
  color: rgb(84, 84, 84);
  margin-right: 4px;
  text-align: left;
}

.profile-email {
  font-size: 16px;
  font-weight: 500;
  color: rgb(84, 84, 84);
}

.profile-button-bar {}

.profile-button {
  width: 70px;
  background-color: rgb(120, 120, 120);
  padding: 6px;
  border-radius: 0.5em;
  /* border-radius: 10px; */
  font-size: 13.5px;
  font-weight: 500;
  text-align: center;
  margin: 5px;
  color: rgb(245, 245, 245);
}

.welcome-title {
  color: rgb(241, 0, 72);
  font-size: 35px;
  font-weight: 900;
  text-shadow:
    -1px -1px 0 white,
    1px -1px 0 white,
    -1px 1px 0 white,
    1px 1px 0 white;
}

.welcome-message-box {
  padding: 0px 5px;
  margin-bottom: 8px;
  border-radius: 10px;
}

.welcome-message {
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin-top: 5px;
  margin-bottom: 5px;
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2px;
  width: 80%;
  margin: 8px auto;
  /* margin: 20px auto; */
}

.grid-item {
  padding: 2px;
  text-align: center;
}

.profile-preview-box {
  width: 200px;
}

.profile-preview-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}


.ImageUpload {
  font-family: sans-serif;
  text-align: center;
  color: gray;
}