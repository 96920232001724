.cart-item-image {
  width: 100%;
  padding: 10px 10px 0px 10px;
  box-sizing: border-box;
}

.cart-item-description-box {
  padding: 6px;
}

.cart-item-title-box {
  margin-bottom: 5px;
  font-size: 15px;
}

.cart-item-price-box {
  padding: 10px;
  width: 80px;
  min-width: 80px;
  text-align: right;
  font-weight: 500;
  color: rgb(50, 50, 50);
}

.cart-item-typical-price-box {
  font-size: 13px;
  font-weight: normal;
}

.cart-checkout-button {
  background-color: rgb(210, 0, 0);
  color: rgb(235, 235, 235);
  /* color: rgb(75, 75, 75); */
  /* background-color: rgb(255, 250, 220);
  color: darkgray; */
  padding: 6px;
  border-radius: 8px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-weight: 600;
}

.cart-item-count {
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(100, 100, 100);
}

.cart-discount-label {
  background-color: rgb(230, 0, 0);
  border-radius: 5px;
  display: block;
  float: right;
  color: white;
  font-size: 13px;
  font-weight: normal;
  padding: 3px 5px;
}

.tl {
  text-decoration: line-through;
}

.cart-item-button {
  background-color: rgb(245, 245, 245);
  display: block;
  float: left;
  /* border: 1px solid rgb(120, 120, 120); */
  font-size: 13px;
  color: rgb(120, 120, 120);
  padding: 1px 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.cart-address-box {
  background-color: rgb(250, 250, 250);
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  color: rgb(70, 70, 70);
}

.cart-address-name {
  font-weight: 700;
}

.cart-card-box {
  width: 65px;
  margin-right: 10px;
  border-radius: 5px;
}

.cart-price {
  font-size: 18px;
  font-weight: 500;
  color: rgb(70, 70, 70);
  text-align: center;
  padding: 0px;
  margin-top: 10px;
}

.cart-confirm {
  font-size: 15px;
  padding: 10px;
  margin: 10px;
  color: rgb(70, 70, 70);
}

.cart-final-step {
  color: rgb(198, 0, 0);
  font-size: 13.5px;
}

.div-horizontal-bar {
  background-color: gray;
  height: 1px;
}

.mlr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mlr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mtb3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.c-warn {
  color: rgb(198, 0, 0);
}

.f-bold {
  font-weight: bold;
}

.fw500 {
  font-weight: 500;
}

.fs13 {
  font-size: 13px;
}

.mt5 {
  margin-top: 5px;
}

.cart-qty-box {
  color: rgb(120, 120, 120);
  font-size: 15px;
}

.cart-item-qty {
  font-weight: normal;
  font-size: 13px;
}

.cart-item-ea-price {
  font-weight: normal;
  font-size: 15px;
}

.cart-discount-expire {
  color: red;
  font-size: 13px;
  font-weight: 700;
}