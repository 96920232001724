.switch {
  position: absolute;
  border-radius: 100px;
  background-color: var(--c60);
  /* background-color: #00629b; */
  width: 30px;
  height: 30px;
}

.switch-off {
  left: -10px;
  transition: left 0.3s ease;
}

.switch-on {
  left: 16px;
  transition: left 0.3s ease;
}

.switch-main {
  display: flex;
  align-items: center;
  justify-content: center;
}