.profile-left-box {
  width: 130px;
  padding-right: 10px;
}

.profile-left-menu {
  font-weight: 600;
  height: 35px;
  border-radius: 0px 100px 100px 0px;
  background-color: rgb(213, 210, 213);
  padding-left: 5px;

  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* text-align: center; */
  margin-bottom: 10px;
  user-select: none;
}

.profile-right-box {
  border-radius: 0px 10px 0px 0px;
  /* background-color: white */
}

.profile-group-title {
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  max-width: 500px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.profile-group-box {
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  min-width: 300px;
  max-width: 500px;
  min-height: 20px;
  background-color: var(--c255);
  box-shadow: 0 4px 8px rgba(200, 200, 200, 0.2), 0 2px 4px rgba(50, 50, 50, 0.4);
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.profile-group-gap {
  height: 30px;
}