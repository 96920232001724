.fs10 {
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs17 {
  font-size: 17px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs22 {
  font-size: 22px;
}

.fs23 {
  font-size: 23px;
}

.fs24 {
  font-size: 24px;
}

.fs25 {
  font-size: 25px;
}

.fs26 {
  font-size: 26px;
}

.fs27 {
  font-size: 27px;
}

.fs28 {
  font-size: 28px;
}

.fs29 {
  font-size: 29px;
}

.fs30 {
  font-size: 30px;
}

.fs31 {
  font-size: 31px;
}

.fs32 {
  font-size: 32px;
}

.fs33 {
  font-size: 33px;
}

.fs34 {
  font-size: 34px;
}

.fs35 {
  font-size: 35px;
}

.fs40 {
  font-size: 40px;
}

.fs45 {
  font-size: 45px;
}

.fs50 {
  font-size: 50px;
}