.login-validation-box {
  color: #555555;
  margin-left: 40px;
  margin-right: 40px;
}

.login-validation-item {
  width: 100%;
}

.login-validation-icon {
  margin-right: 4px;
  font-size: 15px;
}

.login-validation-description {
  font-size: 14px;
}

.green {
  color: green;
}