.small-card-button {
  color: rgb(220, 220, 220);
  font-size: 35px;
  margin-left: 2px;
  margin-right: 2px;
}

.small-card-video-icon {
  color: rgb(146, 60, 60);
  font-size: 20px;
}

.small-card-shop-icon {
  color: rgb(1, 164, 29);
  font-size: 20px;
}

.card-type {
  width: 70%;
  color: white;
  font-size: 11px;
  font-weight: 700;
  border-radius: 4px;
  margin-top: 3px;
  padding: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.small-card-icon-box {
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 60px;
}

.card-description-left {
  /* background-color: black; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 52px;
  height: 70px;
  margin-right: 4px;
}

.card-user-icon {
  height: 35px;
  margin-top: 5px;
  margin-left: 2px;
  border-radius: 50px;
}

.card-description-right {
  /* flex: 1;
  float: right; */

  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.card-main {
  flex: none;
  position: relative;
  padding-top: 56.25%;
  /* background-color: rgb(0, 0, 0); */
}

.card-description {
  display: flex;
  margin-bottom: 4px;
}

.card-bottom-description-box {
  border-radius: 8px;
  background-color: rgb(30, 30, 30);
  width: 100%;
  min-height: 200px;
  margin-top: 8px;
}

.card-side-description-box {
  border-radius: 8px;
  background-color: rgb(30, 30, 30);
  width: 100%;
  /* min-height: 200px; */
  margin-left: 10px;
  margin-bottom: 5px;
}

.big-card-header-icon-box {
  padding: 0px 6px 4px 4px;
}

.big-card-content-box {
  width: 100%;
  color: aliceblue;

  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
}

.big-card-comment-box {
  box-sizing: border-box;
  padding-left: 10px;
}

.big-card-horizontal-main-image {
  display: block;

  width: 100%;
  max-width: 1000px;
  height: auto;
  max-height: 800px;
  margin-left: auto;
  margin-right: auto;
}

.big-card-vertical-main-image {
  display: block;
  /* max-width: 400px; */
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
}

.big-card-vertical-modal-image {
  display: block;
  object-fit: cover;
  width: 100%;
  /* height: 100%; */
  /* max-height: 900px; */
  /* max-width: 400px; */
  /* max-height: 600px; */
  margin-left: auto;
  margin-right: auto;
}

.card-title-box {
  overflow: hidden;
  max-height: 2.6em;
  line-height: 1.3em;

  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shop-color {
  background-color: rgb(30, 210, 72);
}

.news-color {
  background-color: rgb(33, 38, 124);
}

.official-color {
  background-color: rgb(255, 0, 55);
}

.ad-color {
  background-color: rgb(193, 193, 0);
}