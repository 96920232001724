.ccc {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cc {
  justify-content: center;
  align-items: center;
}

.cn {
  justify-content: center;
  /* align-items: center; */
}

.nc {
  /* justify-content: center; */
  align-items: center;
}

.sb {
  align-items: flex-start;
  justify-content: baseline;
}

.center {
  justify-content: center;
  align-items: center;
}

.tal {
  text-align: left;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.vat {
  vertical-align: top;
}