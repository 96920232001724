.ml1 {
  margin-left: 1px;
}

.ml2 {
  margin-left: 2px;
}

.ml3 {
  margin-left: 3px;
}

.ml4 {
  margin-left: 4px;
}

.ml5 {
  margin-left: 5px;
}

.ml6 {
  margin-left: 6px;
}

.ml8 {
  margin-left: 8px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.mr1 {
  margin-right: 1px;
}

.mr2 {
  margin-right: 2px;
}

.mr3 {
  margin-right: 3px;
}

.mr4 {
  margin-right: 4px;
}

.mr5 {
  margin-right: 5px;
}

.mr6 {
  margin-right: 6px;
}

.mr7 {
  margin-right: 7px;
}

.mr8 {
  margin-right: 8px;
}

.mr9 {
  margin-right: 9px;
}

.mr10 {
  margin-right: 10px;
}

.mr11 {
  margin-right: 11px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr35 {
  margin-right: 35px;
}

.mt2 {
  margin-top: 2px;
}

.mt3 {
  margin-top: 3px;
}

.mt4 {
  margin-top: 4px;
}

.mt5 {
  margin-top: 5px;
}

.mt6 {
  margin-top: 6px;
}

.mt7 {
  margin-top: 7px;
}

.mt8 {
  margin-top: 8px;
}

.mt9 {
  margin-top: 9px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb1 {
  margin-bottom: 1px;
}

.mb2 {
  margin-bottom: 2px;
}

.mb3 {
  margin-bottom: 3px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb9 {
  margin-bottom: 9px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb11 {
  margin-bottom: 11px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb13 {
  margin-bottom: 13px;
}

.mb14 {
  margin-bottom: 14px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb100 {
  margin-bottom: 100px;
}

.m0 {
  margin: 0px;
}

.m1 {
  margin: 1px;
}

.m2 {
  margin: 2px;
}

.m3 {
  margin: 3px;
}

.m4 {
  margin: 4px;
}

.m5 {
  margin: 5px;
}

.m6 {
  margin: 6px;
}

.m8 {
  margin: 8px;
}

.m10 {
  margin: 10px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}


.mtb3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mtb5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mtb8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mtb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mlra {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.mtba {
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
}