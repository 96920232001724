.bottom0 {
  bottom: 0px;
}

.bottom5 {
  bottom: 5px;
}

.bottom10 {
  bottom: 10px;
}

.bottom15 {
  bottom: 15px;
}

.bottom20 {
  bottom: 20px;
}

.bottom25 {
  bottom: 25px;
}

.bottom30 {
  bottom: 30px;
}

.bottom40 {
  bottom: 40px;
}

.bottom50 {
  bottom: 50px;
}

.bottom100 {
  bottom: 100px;
}