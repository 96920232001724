
/* Light Theme */
:root[data-theme='light'] .top-logo {
  /* content: url('/public/assets/images/logo1.png'); */
  content: url('/public/assets/images/logo1.png');
}

:root[data-theme='light'] .bp2 {
  background-image: url('/public/assets/images/p1.jpg');
}

/* Dark Theme */
:root[data-theme='dark'] .top-logo {
  content: url('/public/assets/images/logo2.png');
  opacity: 0.8;
}

:root[data-theme='dark'] .bp2 {
  background-image: url('/public/assets/images/p2.jpg');
}

.dim-text {
  color: var(--dim-text);;
}

.dim-back {
  background-color: var(--dim-text);
  color: var(--dim-text);
}

.empty-tab {
  background-color: var(--empty-tab);
}
