.c0 { color: var(--c0); }
.c5 { color: var(--c5); }
.c10 { color: var(--c10); }
.c15 { color: var(--c15); }
.c20 { color: var(--c20); }
.c25 { color: var(--c25); }
.c30 { color: var(--c30); }
.c35 { color: var(--c35); }
.c40 { color: var(--c40); }
.c45 { color: var(--c45); }
.c50 { color: var(--c50); }
.c55 { color: var(--c55); }
.c60 { color: var(--c60); }
.c65 { color: var(--c65); }
.c70 { color: var(--c70); }
.c75 { color: var(--c75); }
.c80 { color: var(--c80); }
.c85 { color: var(--c85); }
.c90 { color: var(--c90); }
.c95 { color: var(--c95); }
.c100 { color: var(--c100); }
.c105 { color: var(--c105); }
.c110 { color: var(--c110); }
.c115 { color: var(--c115); }
.c120 { color: var(--c120); }
.c125 { color: var(--c125); }
.c130 { color: var(--c130); }
.c135 { color: var(--c135); }
.c140 { color: var(--c140); }
.c145 { color: var(--c145); }
.c150 { color: var(--c150); }
.c155 { color: var(--c155); }
.c160 { color: var(--c160); }
.c165 { color: var(--c165); }
.c170 { color: var(--c170); }
.c175 { color: var(--c175); }
.c180 { color: var(--c180); }
.c185 { color: var(--c185); }
.c190 { color: var(--c190); }
.c195 { color: var(--c195); }
.c200 { color: var(--c200); }
.c205 { color: var(--c205); }
.c210 { color: var(--c210); }
.c215 { color: var(--c215); }
.c220 { color: var(--c220); }
.c225 { color: var(--c225); }
.c230 { color: var(--c230); }
.c235 { color: var(--c235); }
.c240 { color: var(--c240); }
.c245 { color: var(--c245); }
.c250 { color: var(--c250); }
.c255 { color: var(--c255); }

.cdt {
  color: #333333;
}

.gold {
  color: gold;
}

.orange {
  color: orange;
}

.darkorange {
  color: darkorange;
}

.red {
  color: red;
}

.green {
  color: green;
}

.silver {
  color: silver;
}

.bronze {
  color: #CD7F32;
}

.wine {
  color: rgb(190, 0, 0)
}

.darkred {
  color: darkred;
}

.sky {
  color: rgb(50, 170, 217);
}

.white {
  color: white;
}

.blue {
  color: blue;
}

.deepblue {
  color: rgb(0, 0, 202);
}

.skyblue {
  color: skyblue;
}

.c-pinchat {
  color: #E60000;
}