:root[data-theme='light'] {
  --chat-background-color: rgb(202, 215, 220);
  --chat-gradient-top: linear-gradient(to bottom, rgba(202, 215, 220, 1), rgba(202, 215, 220, 0));
  --chat-gradient-bottom: linear-gradient(to top, rgba(202, 215, 220, 1), rgba(202, 215, 220, 0));

  --chat-addon-color-thread-title: rgb(211, 197, 178);
  --chat-addon-color-thread: rgb(231, 217, 198);
  --chat-thread-gradient-top: linear-gradient(to bottom, rgba(231, 217, 198, 1), rgba(231, 217, 198, 0));
  --chat-thread-gradient-bottom: linear-gradient(to top, rgba(231, 217, 198, 1), rgba(231, 217, 198, 0));

  --chat-addon-color-profile-title: rgb(211, 197, 80);
	--chat-addon-color-profile: rgb(231, 217, 100);
  --chat-addon-color-info-title: rgb(104, 158, 184);
	--chat-addon-color-info: rgb(124, 178, 204);
}

:root[data-theme='dark'] {
  --chat-background-color: rgb(53, 40, 35);
  --chat-gradient-top: linear-gradient(to bottom, rgba(53, 40, 35, 1), rgba(53, 40, 35, 0));
  --chat-gradient-bottom: linear-gradient(to top, rgba(53, 40, 35, 1), rgba(53, 40, 35, 0));

	--chat-addon-color-thread-title:rgb(27, 30, 39); 
  --chat-addon-color-thread: rgb(24, 48, 57);
  --chat-thread-gradient-top: linear-gradient(to bottom, rgba(24, 48, 57, 1), rgba(24, 48, 57, 0));
  --chat-thread-gradient-bottom: linear-gradient(to top, rgba(24, 48, 57, 1), rgba(24, 48, 57, 0));

  --chat-addon-color-profile-title: rgb(20, 22, 63);
  --chat-addon-color-profile: rgb(8, 23, 87);
  --chat-addon-color-info-title: rgb(1, 19, 34);
	--chat-addon-color-info: rgb(0, 24, 43);
}

.chat-left-inner-layout {
  background-color: var(--chat-background-color);
}

/* gradation-scrollbar-container */
.gradation-scrollbar-container {
  position: relative;
}

.gradation-scrollbar-container::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  pointer-events: none;
  z-index: 1;
}

.gradation-scrollbar-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 8px;
  pointer-events: none;
  z-index: 1;
}

.gradation-scrollbar-container::before {
  top: 0;
  background: var(--chat-gradient-top);
}

.gradation-scrollbar-container::after {
  bottom: 0;
  background: var(--chat-gradient-bottom);
}

/* thread-gradation-scrollbar-container */
.thread-gradation-scrollbar-container {
  position: relative;
}

.thread-gradation-scrollbar-container::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  pointer-events: none;
  z-index: 1;
}

.thread-gradation-scrollbar-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 8px;
  pointer-events: none;
  z-index: 1;
}

.thread-gradation-scrollbar-container::before {
  top: 0;
  background: var(--chat-thread-gradient-top);
}

.thread-gradation-scrollbar-container::after {
  bottom: 0;
  background: var(--chat-thread-gradient-bottom);
}



.thread-header {
  font-size: 15px;
  user-select: none;
}

.chat-main-user-icon {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  margin: 3px;
}
