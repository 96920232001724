:root {
  --header-body-height: 55px;
  --header-body-bottom: 4px;
  --header-height: calc(var(--header-body-height) + var(--header-body-bottom));
  --layout-modal-top-height: 40px;
  --layout-left-menu-width: 50px;
  --layout-left-column-width: 80px;
  --layout-horizontal-card-min-width: 300px;
  --layout-vertical-card-min-width: 200px;
  --layout-square-card-min-width: 250px;
  --layout-channel-card-min-width: 180px;
  --layout-people-card-min-width: 180px;
  --layout-card-gap: 0px;
  --layout-shop-card-gap: 0px;
  --layout-channel-card-gap: 0px;
  --layout-people-card-gap: 0px;

  /* // used not required */
  --layout-right-column-min-width: 350px;
  --layout-right-column-max-width: 400px;
  --layout-empty-before-card-count: 10;
  --layout-empty-after-card-count: 10;

  /* // not used */
  --layout-left-small-column-width: 60px;
  --layout-horizontal-card-max-width: 450px;
  --layout-vertical-card-max-width: 280px;
  --layout-card-menu-top-height: 15px;
  --layout-card-menu-top-padding: 8px;
  --layout-card-bottom-menu-height: 25px;
  --layout-card-bottom-menu-padding: 12px;
}

/* Light Theme */
:root[data-theme='light'] {
  --c0: rgb(0, 0, 0);
  --c5: rgb(5, 5, 5);
  --c10: rgb(10, 10, 10);
  --c15: rgb(15, 15, 15);
  --c20: rgb(20, 20, 20);
  --c25: rgb(25, 25, 25);
  --c30: rgb(30, 30, 30);
  --c35: rgb(35, 35, 35);
  --c40: rgb(40, 40, 40);
  --c45: rgb(45, 45, 45);
  --c50: rgb(50, 50, 50);
  --c55: rgb(55, 55, 55);
  --c60: rgb(60, 60, 60);
  --c65: rgb(65, 65, 65);
  --c70: rgb(70, 70, 70);
  --c75: rgb(75, 75, 75);
  --c80: rgb(80, 80, 80);
  --c85: rgb(85, 85, 85);
  --c90: rgb(90, 90, 90);
  --c95: rgb(95, 95, 95);
  --c100: rgb(100, 100, 100);
  --c105: rgb(105, 105, 105);
  --c110: rgb(110, 110, 110);
  --c115: rgb(115, 115, 115);
  --c120: rgb(120, 120, 120);
  --c125: rgb(125, 125, 125);
  --c130: rgb(130, 130, 130);
  --c135: rgb(135, 135, 135);
  --c140: rgb(140, 140, 140);
  --c145: rgb(145, 145, 145);
  --c150: rgb(150, 150, 150);
  --c155: rgb(155, 155, 155);
  --c160: rgb(160, 160, 160);
  --c165: rgb(165, 165, 165);
  --c170: rgb(170, 170, 170);
  --c175: rgb(175, 175, 175);
  --c180: rgb(180, 180, 180);
  --c185: rgb(185, 185, 185);
  --c190: rgb(190, 190, 190);
  --c195: rgb(195, 195, 195);
  --c200: rgb(200, 200, 200);
  --c205: rgb(205, 205, 205);
  --c210: rgb(210, 210, 210);
  --c215: rgb(215, 215, 215);
  --c220: rgb(220, 220, 220);
  --c225: rgb(225, 225, 225);
  --c230: rgb(230, 230, 230);
  --c235: rgb(235, 235, 235);
  --c240: rgb(240, 240, 240);
  --c245: rgb(245, 245, 245);
  --c250: rgb(250, 250, 250);
  --c255: rgb(255, 255, 255);

  --empty-tab: rgb(220, 220, 220);
}

/* Dark Theme */
:root[data-theme='dark'] {
  --c0: rgb(255, 255, 255);
  --c5: rgb(250, 250, 250);
  --c10: rgb(245, 245, 245);
  --c15: rgb(240, 240, 240);
  --c20: rgb(235, 235, 235);
  --c25: rgb(230, 230, 230);
  --c30: rgb(225, 225, 225);
  --c35: rgb(220, 220, 220);
  --c40: rgb(215, 215, 215);
  --c45: rgb(210, 210, 210);
  --c50: rgb(205, 205, 205);
  --c55: rgb(200, 200, 200);
  --c60: rgb(195, 195, 195);
  --c65: rgb(190, 190, 190);
  --c70: rgb(185, 185, 185);
  --c75: rgb(180, 180, 180);
  --c80: rgb(175, 175, 175);
  --c85: rgb(170, 170, 170);
  --c90: rgb(165, 165, 165);
  --c95: rgb(160, 160, 160);
  --c100: rgb(155, 155, 155);
  --c105: rgb(150, 150, 150);
  --c110: rgb(145, 145, 145);
  --c115: rgb(140, 140, 140);
  --c120: rgb(135, 135, 135);
  --c125: rgb(130, 130, 130);
  --c130: rgb(125, 125, 125);
  --c135: rgb(120, 120, 120);
  --c140: rgb(115, 115, 115);
  --c145: rgb(110, 110, 110);
  --c150: rgb(105, 105, 105);
  --c155: rgb(100, 100, 100);
  --c160: rgb(95, 95, 95);
  --c165: rgb(90, 90, 90);
  --c170: rgb(85, 85, 85);
  --c175: rgb(80, 80, 80);
  --c180: rgb(75, 75, 75);
  --c185: rgb(70, 70, 70);
  --c190: rgb(65, 65, 65);
  --c195: rgb(60, 60, 60);
  --c200: rgb(55, 55, 55);
  --c205: rgb(50, 50, 50);
  --c210: rgb(45, 45, 45);
  --c215: rgb(40, 40, 40);
  --c220: rgb(35, 35, 35);
  --c225: rgb(30, 30, 30);
  --c230: rgb(25, 25, 25);
  --c235: rgb(20, 20, 20);
  --c240: rgb(15, 15, 15);
  --c245: rgb(10, 10, 10);
  --c250: rgb(5, 5, 5);
  --c255: rgb(0, 0, 0);

  --empty-tab: rgb(30, 30, 20);
}

.modal-body {
  height: calc(100vh - var(--header-height) - var(--layout-modal-top-height));
  overflow: hidden;
}
