.b0 { background-color: var(--c0); }
.b5 { background-color: var(--c5); }
.b10 { background-color: var(--c10); }
.b15 { background-color: var(--c15); }
.b20 { background-color: var(--c20); }
.b25 { background-color: var(--c25); }
.b30 { background-color: var(--c30); }
.b35 { background-color: var(--c35); }
.b40 { background-color: var(--c40); }
.b45 { background-color: var(--c45); }
.b50 { background-color: var(--c50); }
.b55 { background-color: var(--c55); }
.b60 { background-color: var(--c60); }
.b65 { background-color: var(--c65); }
.b70 { background-color: var(--c70); }
.b75 { background-color: var(--c75); }
.b80 { background-color: var(--c80); }
.b85 { background-color: var(--c85); }
.b90 { background-color: var(--c90); }
.b95 { background-color: var(--c95); }
.b100 { background-color: var(--c100); }
.b105 { background-color: var(--c105); }
.b110 { background-color: var(--c110); }
.b115 { background-color: var(--c115); }
.b120 { background-color: var(--c120); }
.b125 { background-color: var(--c125); }
.b130 { background-color: var(--c130); }
.b135 { background-color: var(--c135); }
.b140 { background-color: var(--c140); }
.b145 { background-color: var(--c145); }
.b150 { background-color: var(--c150); }
.b155 { background-color: var(--c155); }
.b160 { background-color: var(--c160); }
.b165 { background-color: var(--c165); }
.b170 { background-color: var(--c170); }
.b175 { background-color: var(--c175); }
.b180 { background-color: var(--c180); }
.b185 { background-color: var(--c185); }
.b190 { background-color: var(--c190); }
.b195 { background-color: var(--c195); }
.b200 { background-color: var(--c200); }
.b205 { background-color: var(--c205); }
.b210 { background-color: var(--c210); }
.b215 { background-color: var(--c215); }
.b220 { background-color: var(--c220); }
.b225 { background-color: var(--c225); }
.b230 { background-color: var(--c230); }
.b235 { background-color: var(--c235); }
.b240 { background-color: var(--c240); }
.b245 { background-color: var(--c245); }
.b250 { background-color: var(--c250); }
.b255 { background-color: var(--c255); }

.b1 {
  background-color: aqua;
}

.b2 {
  background-color: chartreuse;
}

.b3 {
  background-color: blueviolet;
}

.b4 {
  background-color: cornflowerblue;
}

.bw {
  background-color: white;
}

.b-silver {
  background-color: silver;
}

.b-green {
  background-color: green;
}

.b-darkgreen {
  background-color: darkgreen;
}

.b-yellow {
  background-color: yellow;
}

.b-orange {
  background-color: orange;
}

.b-white {
  background-color: white;
}

.b-red {
  background-color: red;
}

.b-blue {
  background-color: blue;
}

.b-lightred {
  background-color: rgb(255, 81, 81)
}

.b-wine {
  background-color: rgb(190, 0, 0)
}

.b-lightgray {
  background-color: lightgray;
}

.b-gold {
  background-color: gold;
}

.b-darkred {
  background-color: darkred;
}

.b-icon {
  background-color: rgb(0, 30, 60);
}

.b-on {
  background-color: rgb(0, 255, 0);
}

.b-my {
  background-color: rgb(128, 10, 10);
}

.b-sky {
  background-color: #5eb6d3;
}

.b-loginbtn {
  background-color: #1877f2;
}

.b-pastel-red {
  background-color: #ffb3ba;
}

.b-pastel-orange {
  background-color: #ffdfba;
}

.b-pastel-yellow {
  background-color: #ffffba;
}

.b-pastel-green {
  background-color: #baffc9;
}

.b-pastel-blue { 
  background-color: #bae1ff;
}

.b-channel-tab {
  background-color: #7cb2cc;
}

.b-chat-tab {
  background-color: #9167c9;
}

.b-people-tab {
  background-color: #db553e;
}

.b-pcblue {
  background-color: #1877f2;
}

.b-pattern {
  background-size: cover;
  background-position: center;
}

.fulla {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
}

.transparent {
  background-color: transparent;
}
