.top-1 {
  top: -1px;
}

.top-10 {
  top: -10px;
}

.top-15 {
  top: -15px;
}

.top-20 {
  top: -20px;
}

.top-25 {
  top: -25px;
}

.top-30 {
  top: -30px;
}

.top-35 {
  top: -35px;
}

.top-40 {
  top: -40px;
}

.top-45 {
  top: -45px;
}

.top0 {
  top: 0px;
}

.top5 {
  top: 5px;
}

.top10 {
  top: 10px;
}

.top20 {
  top: 20px;
}

.top30 {
  top: 30px;
}

.top40 {
  top: 40px;
}

.top50 {
  top: 50px;
}

.top60 {
  top: 60px;
}

.top100 {
  top: 100px;
}

.top300 {
  top: 300px;
}

.top500 {
  top: 500px;
}