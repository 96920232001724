.o0 {
  order: 0;
}

.o1 {
  order: 1;
}

.o2 {
  order: 2;
}

.o3 {
  order: 3;
}

.o4 {
  order: 4;
}

.o5 {
  order: 5;
}

.o6 {
  order: 6;
}

.o7 {
  order: 7;
}

.o8 {
  order: 8;
}

.o9 {
  order: 9;
}

.o10 {
  order: 10;
}