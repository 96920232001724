.homelayout-ui {
  background-color: var(--c250);
  min-width: 600px;
  overflow-y: hidden;
  overflow: hidden;
}

.homelayout-body {
  display: flex;
  flex-direction: row;

  height: calc(100vh - var(--header-height));
  /* height: -webkit-calc(100vh - var(--header-height));
  height: -moz-calc(100vh - var(--header-height)); */
}

.homelayout-column-chat {
  border-radius: 7px 7px 7px 7px;
  padding-left: 10px;
  padding-right: 10px;

  float: left;
  width: 600px;
  min-width: 600px;

  color: #555555;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  overflow: -moz-scrollbars-vertical;
  color: #222;
}

.homelayout-column-left {
  /* background-color: #61dafb; */
  border-radius: 7px 7px 7px 7px;
  padding-left: 10px;
  padding-right: 10px;

  float: left;
  width: var(--layout-left-column-width);
  min-width: var(--layout-left-column-width);

  color: #555555;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  overflow: -moz-scrollbars-vertical;
  color: #222;
  /* margin-bottom: 10px; */

  /* max-height: calc(100vh - 220px);
  max-height: -webkit-calc(100vh - 220px);
  max-height: -moz-calc(100vh - 220px); */
}

/* .small-left-menu-grop {
  background-color: rgb(255, 255, 255);
  margin-bottom: 12px;
  border-radius: 0px 13px 13px 0px;

  border-style: solid;
  border-color: #f0f0f0;
  border-width: 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.homelayout-column-center {
  /* background-color: #707070; */
  border-radius: 7px 7px 7px 7px;
  padding-left: 10px;
  padding-right: 10px;

  flex-grow: 1;
  min-width: 520px;

  position: relative;
  margin: auto;

  width: -webkit-calc((100% - 250px) * 0.6);
  width: -moz-calc((100% - 250px) * 0.6);
  width: calc((100% - 250px) * 0.6);

  position: relative;
}

.homelayout-column-right {
  /* background-color: #6ea8ff; */
  border-radius: 7px 7px 7px 7px;
  padding-left: 10px;
  padding-right: 10px;

  float: right;

  position: relative;
  width: -webkit-calc((100% - 200px) * 0.4);
  min-width: var(--layout-right-column-min-width);
  max-width: var(--layout-right-column-max-width);

  /* width: -webkit-calc((100% - 200px) * 0.4);
  width: -moz-calc((100% - 200px) * 0.4);
  width: calc((100% - 200px) * 0.4);
  min-width: 380px; */
  /* max-width: 500px; */
  /* padding-top: 10px; */


  /* 
  border-radius: 7px 0px 0px 7px;
  background-color: #f9f9f9;
  -webkit-box-shadow: 0 3px 4px -4px #555;
  -moz-box-shadow: 0 3px 4px -4px #555;
  box-shadow: 0 3px 4px -4px #555;
  z-index: 20; */
}

.layout-round-rect {
  /* display: flex; */
  /* background-color: white; */
  border-radius: 7px 7px 7px 7px;
  /* width: 100%; */
  /* height: 300px; */
}


.card-layout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--layout-card-gap);
}

.one-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex: 1;
  gap: var(--layout-card-gap);

  /* min-width: var(--layout-horizontal-card-min-width); */
}

.selected {
  width: 100%;
  padding: 10px;
  min-width: 250px;
  background-color: rgb(243, 255, 205);
  border: 1px solid #ccc;
  border-radius: 7px;
}

.one-big-card {
  box-sizing: border-box;
  width: 100%;
  min-width: 250px;
  padding: 8px;
  background-color: rgb(0, 0, 0);
  color: rgb(220, 220, 220);
  /* border: 1px solid #ccc; */
  border-radius: 7px;
  /* min-height: 600px; */
}

.one-big-light-card {
  box-sizing: border-box;
  width: 100%;
  min-width: 250px;
}