.lh0 {
  line-height: 0;
}

.lhn {
  line-height: normal;
}


.int-height {
  image-rendering: pixelated;
  transform: translateZ(0);
}

.hide {
  display: none;
  overflow: hidden;
  width: 0px;
  height: 0px;
}

.bd-c {
  border: 1px solid #ccc;
}

.fr {
  display: flex;
  flex-direction: row;
}

.fc {
  display: flex;
  flex-direction: column;
}

.df {
  display: flex;
}

.dn {
  display: 'none';
}

.r100 {
  border-radius: 100px;
}

.money-label {
  font-size: 13px;
  font-weight: 500;
  color: #888888;
}

.shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.cancel-line {
  text-decoration: line-through;
}

.block {
  display: block;
}

.bl {
  display: block;
  float: left;
}

.oh {
  overflow: hidden;
}

.bsb {
  box-sizing: border-box;
}

.badge {
  display: block;
  float: right;
  /* border-radius: 6px; */
  overflow: hidden;
  box-sizing: border-box;
}

.box {
  box-sizing: border-box;
}

.pen {
  pointer-events: none;
}

.usn {
  user-select: none;
}

.header-title-icon {
  font-size: 19px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.nowrap {
  white-space: nowrap;
}

.gray-border {
  border: 1px solid var(--c220);
}

.box-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--layout-card-gap);
}

.shadow-box2 {
  box-shadow: 0 4px 8px rgba(200, 200, 200, 0.2), 0 2px 4px rgba(50, 50, 50, 0.4);
}

.child-box {
  float: left;
  /* margin: 0px 5px 5px 0px; */
  box-sizing: border-box;
}

.top-banner-image {
  position: absolute;
  top: -10px;
  width: 1500px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 200px, rgba(0, 0, 0, 0));
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.bottom-banner-image {
  position: absolute;
  top: 10px;
  width: 1500px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 200px, rgba(0, 0, 0, 0));
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  overflow: hidden;
}

.full {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.full-50 {
  left: 50px;
  right: 50px;
  top: 50px;
  bottom: 50px;
}

.short-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  box-orient: vertical;
  text-align: left;
}

.full-description {
  display: block;
  text-align: left;
}

.italic {
  font-style: italic;
}
