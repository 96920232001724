.op10 {
  opacity: 0.1;
}

.op20 {
  opacity: 0.2;
}

.op30 {
  opacity: 0.3;
}

.op40 {
  opacity: 0.4;
}

.op50 {
  opacity: 0.5;
}

.op60 {
  opacity: 0.6;
}

.op70 {
  opacity: 0.7;
}

.op80 {
  opacity: 0.8;
}

.op85 {
  opacity: 0.85;
}

.op90 {
  opacity: 0.9;
}

.op91 {
  opacity: 0.91;
}

.op92 {
  opacity: 0.92;
}

.op93 {
  opacity: 0.93;
}

.op94 {
  opacity: 0.94;
}

.op95 {
  opacity: 0.95;
}

.op96 {
  opacity: 0.96;
}

.op97 {
  opacity: 0.97;
}

.op98 {
  opacity: 0.98;
}

.op99 {
  opacity: 0.99;
}